import React, { ElementType } from "react"
import { StyleProp, ViewStyle } from "react-native"
import IconButton from "../IconButton"
import { CircularIcon, CloseIcon } from "../Icons"
import { Box, Text, useTheme, Color } from "../UI"

interface BannerProps {
  kind: "info" | "alert" | "error" | "success" | "subtle"
  title?: string
  message: string
  rounded?: boolean
  onDismiss?: Function
  style?: StyleProp<ViewStyle>
  Icon?: ElementType
  Footer?: ElementType
}

export default function Banner({
  kind = "info",
  title,
  message,
  rounded,
  onDismiss,
  style,
  Icon,
  Footer,
}: BannerProps) {
  const { getColor } = useTheme()
  let bgColor: Color | undefined =
    kind === "error"
      ? "red"
      : kind === "success"
      ? "green"
      : kind === "alert"
      ? undefined
      : kind === "info"
      ? "contrast95"
      : "contrast05"
  let iconColor: Color =
    kind === "error"
      ? "red15"
      : kind === "success"
      ? "green15"
      : kind === "alert"
      ? "yellow"
      : kind === "info"
      ? "contrast90"
      : "contrast20"
  let textColor: Color =
    kind === "error" || kind === "success"
      ? "white"
      : kind === "info"
      ? "contrast0"
      : "contrast95"
  let borderColor =
    kind === "alert"
      ? getColor("yellow")
      : kind === "subtle"
      ? getColor("contrast10")
      : "transparent"

  return (
    <Box
      p={16}
      r={rounded ? 8 : undefined}
      bg={bgColor}
      style={[
        {
          borderWidth: 2,
          borderColor,
        },
        style,
      ]}>
      <Box style={{ flexDirection: "row" }}>
        {Icon ? (
          <Box mr={12}>
            <CircularIcon
              Icon={Icon}
              backgroundColor={iconColor}
              color={textColor}
              size={24}
            />
          </Box>
        ) : null}
        <Box style={{ flex: 1 }}>
          {title ? (
            <Box pb={8}>
              <Text type="heading5" color={textColor}>
                {title}
              </Text>
            </Box>
          ) : null}
          <Text type="baseText" color={textColor}>
            {message}
          </Text>
          {Footer ? <Box mt={8}>{Footer}</Box> : null}
        </Box>
        {onDismiss ? (
          <IconButton
            Icon={CloseIcon}
            backgroundColor="transparent"
            color={textColor}
            onPress={onDismiss}
          />
        ) : null}
      </Box>
    </Box>
  )
}
