import {
  TransactionTemplateFragment,
  namedOperations,
  useCreateTemplateRequestWebMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  Badge,
  Button,
  Group,
  Input,
  MultiSelect,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import { MaskedInput } from "react-text-mask"
import * as yup from "yup"
import { isValidEmail, isValidState } from "./CreateEMDRequestForm"
import EscrowSearchSelect from "./EscrowSearchSelect"

export default function CreateEMDPartialRequestForm({
  onSubmitSuccess,
}: {
  onSubmitSuccess?: (transactionTemplate: TransactionTemplateFragment) => void
}) {
  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  const form = useForm({
    initialValues: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      stateOrRegion: "",
      postalCode: "",
      escrowAccountId: null,
      agentFirstName: "",
      agentLastName: "",
      agentEmail: "",
      additionalEmails: [],
      newEmail: "",
    },
    validate: yupResolver(
      yup.object({
        addressLine1: yup.string().required("Required"),
        addressLine2: yup.string(),
        city: yup.string().required("Required"),
        stateOrRegion: yup
          .string()
          .required("Required")
          .test("State", "Invalid State Code", isValidState),
        postalCode: yup.string().required("Required"),
        escrowAccountId: yup.string().required("Required"),
        agentFirstName: yup.string().required("Required"),
        agentLastName: yup.string().required("Required"),
        agentEmail: yup.string().email("Invalid Email").required("Required"),
        additionalEmails: yup.array().of(yup.string().email()),
        newEmail: yup.string().email("Invalid Email"),
      }),
    ),
  })

  const [
    createTemplateRequestWeb,
    createTemplateRequestWebState,
  ] = useCreateTemplateRequestWebMutation()

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        try {
          const request = await createTemplateRequestWeb({
            variables: {
              escrowAccountId: values.escrowAccountId,
              additionalEmails: values.additionalEmails,
              formData: [
                {
                  name: "propaddress",
                  value: `${values.addressLine1}${
                    values.addressLine2 ? ` ${values.addressLine2}` : ""
                  }, ${values.city}, ${values.stateOrRegion} ${
                    values.postalCode
                  }`,
                },
                { name: "address_line_1", value: values.addressLine1 || "" },
                { name: "address_line_2", value: values.addressLine2 || "" },
                { name: "city", value: values.city || "" },
                {
                  name: "state_or_region",
                  value: values.stateOrRegion
                    ? values.stateOrRegion.toUpperCase()
                    : "",
                },
                { name: "postal_code", value: values.postalCode || "" },
                { name: "agent_role", value: "listing" },
                { name: "agentemail", value: values.agentEmail || "" },
                { name: "agentfirst", value: values.agentFirstName || "" },
                { name: "agentlast", value: values.agentLastName || "" },
              ],
            },
            refetchQueries: [namedOperations.Query.TransactionTemplates],
          })
          notifications.show({
            color: "green",
            title: "Success",
            message: "Request sent!",
          })
          onSubmitSuccess?.(request.data.createTemplateRequestWeb)
        } catch (error) {
          notifications.show({
            color: "red",
            title: "Error",
            message: error.message,
          })
        }
      })}>
      <Title size="h2" order={2}>
        Request Payment
      </Title>
      <Stack>
        <Space h="xl" />
        <Title order={5}>What’s the property address?</Title>
        <TextInput
          label="Address Line 1"
          {...form.getInputProps("addressLine1")}
        />
        <TextInput
          label="Address Line 2 (Optional)"
          {...form.getInputProps("addressLine2")}
        />
        <Group grow align="start">
          <TextInput
            label="City"
            {...form.getInputProps("city")}
            sx={{ flexGrow: 3, maxWidth: "auto" }}
          />
          <Input.Wrapper
            label="State"
            sx={{ width: 100, maxWidth: "auto" }}
            {...form.getInputProps("stateOrRegion")}>
            <Input
              component={MaskedInput}
              mask={[/[a-zA-Z]/, /[a-zA-Z]/]}
              guide={false}
              {...form.getInputProps("stateOrRegion")}
              onChange={(e) =>
                form.setFieldValue(
                  "stateOrRegion",
                  e.target.value.toUpperCase(),
                )
              }
            />
          </Input.Wrapper>
        </Group>
        <TextInput
          label="Zip Code"
          {...form.getInputProps("postalCode")}
          sx={{ width: 200 }}
        />
        <Space h="xl" />
        <Title order={5}>Who’s holding escrow?</Title>
        <EscrowSearchSelect
          label="Escrow Holder"
          stateCode={form.values.stateOrRegion}
          {...form.getInputProps("escrowAccountId")}
        />
        <Space h="xl" />
        <Title order={5}>Who has the payer’s information?</Title>
        <Text size="sm">
          Once you’ve sent the request the buyer’s agent will be able to fill in
          the payer’s information. You will continue to see this transaction in
          a draft state until the buyer’s agent has completed the payment
          request.
        </Text>
        <Group grow align="start">
          <TextInput
            label="First Name"
            {...form.getInputProps("agentFirstName")}
          />
          <TextInput
            label="Last Name"
            {...form.getInputProps("agentLastName")}
          />
        </Group>
        <TextInput label="Email" {...form.getInputProps("agentEmail")} />
        <Space h="xl" />
        <Title order={5}>Who should receive notifications?</Title>
        <Text size="sm">
          Add email addreses of those you want notified about this payment
          request. Use this section to add others, like listing agent, lender or
          office administrator.
        </Text>
        <Stack spacing="sm" align="start">
          <Badge color="dark" size="lg">
            {user.email} (You)
          </Badge>
          {isValidEmail(form.values.agentEmail) ? (
            <Badge color="dark" size="lg">
              {form.values.agentEmail} (Buyer Agent)
            </Badge>
          ) : null}
        </Stack>
        <MultiSelect
          {...form.getInputProps("additionalEmails")}
          placeholder="Add additional notification recipients"
          data={form.values.additionalEmails}
          error={form.errors.additionalEmails || form.errors.newEmail}
          searchable={true}
          searchValue={form.values.newEmail}
          onSearchChange={(query) => {
            form.setFieldValue("newEmail", query)
            form.validateField("newEmail")
          }}
          creatable={true}
          getCreateLabel={(query) => `Click to add new email: ${query}`}
          onCreate={(query) => {
            if (!form.errors.newEmail) {
              form.setFieldValue(
                "additionalEmails",
                form.values.additionalEmails.concat(query.trim()),
              )
              return query
            }
            return
          }}
        />
      </Stack>
      <PanelFooter>
        <Button
          size="lg"
          type="submit"
          loading={createTemplateRequestWebState.loading}>
          Send Request
        </Button>
      </PanelFooter>
    </form>
  )
}
