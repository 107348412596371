import {
  EscrowAccountFragment,
  StateCode,
  useEscrowAccountsListQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { Loader, Select, Text, SelectProps } from "@mantine/core"
import { forwardRef, useState } from "react"
import { RiSearchLine } from "react-icons/ri"

interface EscrowSearchSelectProps extends Omit<SelectProps, "data"> {
  stateCode: StateCode
  initialEscrowAccount?: EscrowAccountFragment
}

export default function EscrowSearchSelect({
  stateCode,
  initialEscrowAccount,
  ...rest
}: EscrowSearchSelectProps) {
  const [searchValue, setSearchValue] = useState("")

  const escrowAccountsQuery = useEscrowAccountsListQuery({
    variables: {
      limit: 8,
      stateCode,
      lookup: {
        name: searchValue || undefined,
      },
    },
  })

  let data = []
  if (escrowAccountsQuery.data?.escrowAccountsList?.entries) {
    escrowAccountsQuery.data.escrowAccountsList.entries.forEach((x) => {
      data.push({
        value: x.id,
        label: x.name,
        escrowAccount: x,
      })
    })
  }
  if (
    initialEscrowAccount &&
    !data.find((x) => x.value === initialEscrowAccount.id)
  ) {
    data.push({
      value: initialEscrowAccount.id,
      label: initialEscrowAccount.name,
      escrowAccount: initialEscrowAccount,
    })
  }

  return (
    <Select
      {...rest}
      icon={<RiSearchLine size={16} />}
      rightSection={escrowAccountsQuery.loading ? <Loader size={16} /> : null}
      filter={() => true}
      searchable={true}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      disabled={!stateCode}
      placeholder={stateCode ? "Search..." : "First enter a valid address"}
      itemComponent={SelectItem}
      data={data}
    />
  )
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  value: string
  label: string
  escrowAccount: EscrowAccountFragment
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, escrowAccount, ...rest }: ItemProps, ref) => (
    <div ref={ref} {...rest}>
      <Text size="sm">{escrowAccount.name}</Text>
      <Text size="xs" color="dimmed">
        {escrowAccount.organization?.name}
      </Text>
    </div>
  ),
)
