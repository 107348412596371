import {
  EscrowAccountFragment,
  EscrowAccountsListQueryResult,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import {
  Box,
  Button,
  Group,
  Loader,
  Stack,
  Table,
  Text,
  Badge,
} from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useState } from "react"
import { RiAddLine, RiCheckboxCircleFill } from "react-icons/ri"
import { Link, useHistory } from "react-router-dom"

export default function EscrowAccountsTable({
  query,
  getItemLink,
  getInviteLink,
  viewerRole,
}: {
  query: EscrowAccountsListQueryResult
  getItemLink?: (escrowAccount: EscrowAccountFragment) => object | string
  getInviteLink: () => object | string
  viewerRole?: "superuser" | "admin" | "agent"
}) {
  const history = useHistory()

  const items = query.data?.escrowAccountsList?.entries
  const pageInfo = query.data?.escrowAccountsList?.pageInfo

  const [paginating, setPaginating] = useState(false)

  return (
    <>
      {items?.length ? (
        <Table
          sx={(theme) => ({
            "&[data-hover] tbody tr:hover": {
              cursor: getItemLink ? "cursor" : "default",
            },
          })}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Operating Regions</th>
              <th>Organization</th>
              {viewerRole !== "agent" ? (
                <>
                  <th>Approvals Required</th>
                  <th>Gated</th>{" "}
                </>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {items?.map((item) => (
              <tr
                key={item.id}
                style={{
                  opacity: item.active ? undefined : "50%",
                }}
                onClick={(e) => {
                  if (getItemLink) {
                    if (e.target instanceof HTMLInputElement) return
                    history.push(getItemLink(item))
                  }
                }}>
                <td>
                  <Text>{item.name}</Text>
                </td>
                <td>
                  {item.operatingRegions ? (
                    <Group spacing={4}>
                      {item.operatingRegions.slice(0, 3).map((x) => (
                        <Badge color="gray">{x}</Badge>
                      ))}
                      {item.operatingRegions.length > 3 ? (
                        <Text size="sm">
                          +{item.operatingRegions.length - 3}
                        </Text>
                      ) : null}
                    </Group>
                  ) : null}
                </td>
                <td>
                  <Text>{item.organization?.name}</Text>
                </td>
                {viewerRole !== "agent" ? (
                  <>
                    <td>
                      {item.approvalsRequired ? (
                        <RiCheckboxCircleFill size={20} />
                      ) : null}
                    </td>
                    <td>
                      {item.gated ? <RiCheckboxCircleFill size={20} /> : null}
                    </td>
                  </>
                ) : null}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
      {items?.length ? (
        <Group py="md" position="apart">
          {pageInfo?.hasMoreEntries ? (
            <Button
              variant="white"
              loading={query.loading || paginating}
              onClick={async () => {
                try {
                  setPaginating(true)
                  await query.fetchMore({
                    variables: {
                      afterCursor: pageInfo?.afterCursor || "",
                    },
                    updateQuery: (prev: any, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev
                      return {
                        ...prev,
                        transactionTemplates: {
                          pageInfo:
                            fetchMoreResult?.escrowAccountsList?.pageInfo,
                          entries: [
                            ...prev.escrowAccountsList?.entries,
                            ...(fetchMoreResult?.escrowAccountsList?.entries ||
                              []),
                          ],
                        },
                      }
                    },
                  })
                } catch (error) {
                  console.error(error)
                  notifications.show({
                    color: "red",
                    title: "Error",
                    message: error.message,
                  })
                } finally {
                  setPaginating(false)
                }
              }}>
              Load more
            </Button>
          ) : null}
          <Box />
          <Text color="dimmed" size="sm">
            {pageInfo?.totalCount} total
          </Text>
        </Group>
      ) : query.loading ? (
        <Group spacing="sm">
          <Loader size="sm" />
          <Text>Loading...</Text>
        </Group>
      ) : query.error ? (
        <Text color="red">{query.error.message}</Text>
      ) : (
        <Text color="dimmed">Nothing here yet.</Text>
      )}
      {!query.loading && getInviteLink ? (
        <Stack align="start" spacing="sm">
          <Text>Don’t see the escrow holder you’re looking for?</Text>
          <Link to={getInviteLink()} passHref>
            <Button component="a" color="green" leftIcon={<RiAddLine />}>
              Invite them
            </Button>
          </Link>
        </Stack>
      ) : null}
    </>
  )
}
