import {
  TransactionTemplateFragment,
  namedOperations,
  useCreateTemplateRequestWebMutation,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import {
  ActionIcon,
  Badge,
  Button,
  Group,
  Input,
  MultiSelect,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import { State } from "country-state-city"
import numeral from "numeral"
import { RiQuestionFill } from "react-icons/ri"
import MaskedInput from "react-text-mask"
import { createNumberMask } from "text-mask-addons"
import * as yup from "yup"
import EscrowSearchSelect from "./EscrowSearchSelect"

export function phoneMask(rawValue: string) {
  const phoneMaskWithoutCountryCode = [/[1-9]/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/] // prettier-ignore
  if (rawValue.length > 0 && rawValue[0] === "1") {
    return ["1", "-", ...phoneMaskWithoutCountryCode]
  }
  return phoneMaskWithoutCountryCode
}

export function isValidAmount(amount: string) {
  return numeral(amount).value() >= 36
}

export function isValidState(state: string) {
  return !!State.getStatesOfCountry("US").find(
    (x) => x.isoCode === state.toUpperCase(),
  )
}

export function isValidEmail(email: string) {
  if (!email || !email.includes("@") || !email.includes(".")) return false
  try {
    yup.string().email().required().validateSync(email)
    return true
  } catch (error) {
    return false
  }
}

export default function CreateEMDRequestForm({
  transactionTemplate,
  onSubmitSuccess,
}: {
  transactionTemplate?: TransactionTemplateFragment
  onSubmitSuccess?: (transactionTemplate: TransactionTemplateFragment) => void
}) {
  const formDataObj = transactionTemplate?.formData?.reduce((obj, field) => {
    obj[field.name] = field.value
    return obj
  }, {})

  const form = useForm({
    initialValues: {
      amount: transactionTemplate?.amount || "",
      addressLine1: formDataObj?.["address_line_1"] || "",
      addressLine2: formDataObj?.["address_line_2"] || "",
      city: formDataObj?.["city"] || "",
      stateOrRegion: formDataObj?.["state_or_region"] || "",
      postalCode: formDataObj?.["postal_code"] || "",
      escrowAccountId: transactionTemplate?.escrowAccount?.id || "",
      payerFirstName: transactionTemplate?.buyer?.firstName || "",
      payerLastName: transactionTemplate?.buyer?.lastName || "",
      payerEmail: transactionTemplate?.receiverEmail || "",
      payerPhone: transactionTemplate?.buyer?.phone || "",
      additionalEmails: transactionTemplate?.additionalEmails || [],
      newEmail: "",
    },
    validate: yupResolver(
      yup.object({
        amount: yup
          .string()
          .required("Required")
          .test("Min", `Minimum $36`, isValidAmount),
        addressLine1: yup.string().required("Required"),
        addressLine2: yup.string(),
        city: yup.string().required("Required"),
        stateOrRegion: yup
          .string()
          .required("Required")
          .test("State", "Invalid State Code", isValidState),
        postalCode: yup.string().required("Required"),
        escrowAccountId: yup.string().required("Required"),
        payerFirstName: yup.string().required("Required"),
        payerLastName: yup.string().required("Required"),
        payerEmail: yup.string().email("Invalid Email").required("Required"),
        payerPhone: yup.string(),
        additionalEmails: yup.array().of(yup.string().email()),
        newEmail: yup.string().email("Invalid Email"),
      }),
    ),
  })

  const userQuery = useUserQuery()
  const user = userQuery.data?.user

  const [
    createTemplateRequestWeb,
    createTemplateRequestWebState,
  ] = useCreateTemplateRequestWebMutation()

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        try {
          const request = await createTemplateRequestWeb({
            variables: {
              transactionTemplateId: transactionTemplate?.id || undefined,
              amount: parseInt(
                (numeral(values.amount).value() * 100).toFixed(0),
              ),
              escrowAccountId: values.escrowAccountId,
              receiverEmail: values.payerEmail,
              additionalEmails: values.additionalEmails,
              formData: [
                {
                  name: "propaddress",
                  value: `${values.addressLine1}${
                    values.addressLine2 ? ` ${values.addressLine2}` : ""
                  }, ${values.city}, ${values.stateOrRegion} ${
                    values.postalCode
                  }`,
                },
                {
                  name: "address_line_1",
                  value: values.addressLine1 || "",
                },
                {
                  name: "address_line_2",
                  value: values.addressLine2 || "",
                },
                { name: "city", value: values.city || "" },
                {
                  name: "state_or_region",
                  value: values.stateOrRegion
                    ? values.stateOrRegion.toUpperCase()
                    : "",
                },
                {
                  name: "postal_code",
                  value: values.postalCode || "",
                },
                { name: "agent_role", value: "buyer" },
                { name: "agentemail", value: user?.email || "" },
                {
                  name: "agentfirst",
                  value: user?.firstName || "",
                },
                {
                  name: "agentlast",
                  value: user?.lastName || "",
                },
                {
                  name: "payerfirst",
                  value: values.payerFirstName || "",
                },
                {
                  name: "payerlast",
                  value: values.payerLastName || "",
                },
                {
                  name: "buyer_phone",
                  value: values.payerPhone || "",
                },
              ],
            },
            refetchQueries: [namedOperations.Query.TransactionTemplates],
          })
          onSubmitSuccess?.(request.data?.createTemplateRequestWeb)
          notifications.show({
            color: "green",
            title: "Success",
            message: "Request sent",
          })
        } catch (error) {
          notifications.show({
            color: "red",
            title: "Error",
            message: error.message,
          })
        }
      })}>
      <Title size="h2" order={2}>
        Request Payment
      </Title>
      <Stack>
        <Space h="xl" />
        <Title order={5}>What’s the earnest money amount?</Title>
        <Group spacing={0}>
          <Text size="sm">Enter the amount in the contract.</Text>
          <Tooltip
            position="bottom"
            multiline={true}
            width={300}
            label="The standard limit is $100,000. If the earnest request is for more than the limit, send multiple requests for the same property.">
            <ActionIcon
              component="a"
              target="_blank"
              href="https://earnnest.com/resources/faq"
              variant="subtle"
              radius="xl">
              <RiQuestionFill size={20} />
            </ActionIcon>
          </Tooltip>
        </Group>
        <Input.Wrapper
          label="Amount"
          {...form.getInputProps("amount")}
          sx={{ width: 200 }}>
          <Input
            placeholder="$0.00"
            component={MaskedInput}
            mask={createNumberMask({ allowDecimal: true })}
            guide={false}
            {...form.getInputProps("amount")}
          />
        </Input.Wrapper>
        <Space h="xl" />
        <Title order={5}>What’s the property address?</Title>
        <TextInput
          label="Address Line 1"
          {...form.getInputProps("addressLine1")}
        />
        <TextInput
          label="Address Line 2 (Optional)"
          {...form.getInputProps("addressLine2")}
        />
        <Group grow align="start">
          <TextInput
            label="City"
            {...form.getInputProps("city")}
            sx={{ flexGrow: 3, maxWidth: "auto" }}
          />
          <Input.Wrapper
            label="State"
            sx={{ width: 100, maxWidth: "auto" }}
            {...form.getInputProps("stateOrRegion")}>
            <Input
              component={MaskedInput}
              mask={[/[a-zA-Z]/, /[a-zA-Z]/]}
              guide={false}
              {...form.getInputProps("stateOrRegion")}
              onChange={(e) =>
                form.setFieldValue(
                  "stateOrRegion",
                  e.target.value.toUpperCase(),
                )
              }
            />
          </Input.Wrapper>
        </Group>
        <TextInput
          label="Zip Code"
          {...form.getInputProps("postalCode")}
          sx={{ width: 200 }}
        />
        <Space h="xl" />
        <Title order={5}>Who’s holding escrow?</Title>
        <EscrowSearchSelect
          label="Escrow Holder"
          stateCode={form.values.stateOrRegion}
          initialEscrowAccount={transactionTemplate?.escrowAccount}
          {...form.getInputProps("escrowAccountId")}
        />
        <Space h="xl" />
        <Title order={5}>Who’s paying?</Title>
        <Group grow align="start">
          <TextInput
            label="First Name"
            {...form.getInputProps("payerFirstName")}
          />
          <TextInput
            label="Last Name"
            {...form.getInputProps("payerLastName")}
          />
        </Group>
        <Group grow align="start">
          <TextInput label="Email" {...form.getInputProps("payerEmail")} />
          <Input.Wrapper label="Phone" {...form.getInputProps("payerPhone")}>
            <Input
              component={MaskedInput}
              mask={phoneMask}
              guide={false}
              {...form.getInputProps("payerPhone")}
            />
          </Input.Wrapper>
        </Group>
        <Space h="xl" />
        <Title order={5}>Who should receive notifications?</Title>
        <Text size="sm">
          Add email addreses of those you want notified about this payment
          request. Use this section to add others, like listing agent, lender or
          office administrator.
        </Text>
        <Stack spacing="sm" align="start">
          <Badge color="dark" size="lg">
            {user.email} (You)
          </Badge>
          {isValidEmail(form.values.payerEmail) ? (
            <Badge color="dark" size="lg">
              {form.values.payerEmail} (Payer)
            </Badge>
          ) : null}
        </Stack>
        <MultiSelect
          {...form.getInputProps("additionalEmails")}
          placeholder="Add additional notification recipients"
          data={form.values.additionalEmails}
          error={form.errors.additionalEmails || form.errors.newEmail}
          searchable={true}
          searchValue={form.values.newEmail}
          onSearchChange={(query) => {
            form.setFieldValue("newEmail", query)
            form.validateField("newEmail")
          }}
          creatable={true}
          getCreateLabel={(query) => `Click to add new email: ${query}`}
          onCreate={(query) => {
            if (!form.errors.newEmail) {
              form.setFieldValue(
                "additionalEmails",
                form.values.additionalEmails.concat(query.trim()),
              )
              return query
            }
            return
          }}
        />
      </Stack>
      <PanelFooter>
        <Button
          size="lg"
          type="submit"
          color="green"
          loading={createTemplateRequestWebState.loading}>
          Send Request
        </Button>
      </PanelFooter>
    </form>
  )
}
